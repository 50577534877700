import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./Products.css";
import { isValidEmail } from "../../../utils/validFormData";
import axios from "axios";

const ProductModal = ({ showModal, handleModalClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [formStatus, setFormStatus] = useState({
    isLoading: false,
    isSuccess: false,
    error: null,
  });
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    company_name: "",
  });
  const resetForm = () => {
    handleModalClose();
    setErrorMessage(null);
    setUserInfo({ name: "", email: "", company_name: "" });
    setFormStatus({ isLoading: false, isSuccess: false, error: null });
  };
  const handleSubmit = async () => {
    if (!userInfo.email) {
      setErrorMessage("Email is required!");
    } else if (!isValidEmail(userInfo.email)) {
      setErrorMessage("Email is not valid");
    } else {
      setErrorMessage("");
      await handleEmailSubmission({
        first_name: userInfo.name,
        email: userInfo.email,
        company_name: userInfo.company_name,
      });
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleEmailSubmission = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(
        `${process.env.REACT_APP_APIURL}contact`,
        { ...data, category: "Inquiry - Fx AI Pilot" },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setFormStatus({ isLoading: false, isSuccess: true });
      setTimeout(resetForm, 2000);
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };
  useEffect(() => {
    if (formStatus.error) {
      setTimeout(() => setFormStatus({ ...formStatus, error: null }), 3000);
    }
  }, [formStatus]);
  return (
    <Modal
      show={showModal}
      onHide={resetForm}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body>
        <div style={{padding:"8px"}}>
        <div className="title modal-title">
          <h2 className="gradient-text-default text-center">Fx AI Pilot</h2>
        </div>
        <div className="text-center mx-auto modal-pr-desc">
          Fx AI Pilot, a product of fxis.ai, is a cutting-edge tool that
          automates code updates by analyzing repositories and creating pull
          requests. For more details, contact us via the form below.
        </div>
        <form
          className="d-flex flex-column product-form"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="form-group">
            <label className="form-label" htmlFor="name">
              Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={userInfo.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              Email Id<span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={userInfo.email}
              onChange={handleInputChange}
            />
          </div>
          <small className="form-text text-danger">{errorMessage}</small>
          <div className="form-group">
            <label className="form-label" htmlFor="company">
              Company Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="company_name"
              value={userInfo.company}
              onChange={handleInputChange}
            />
          </div>
          <div className="prod-modal-btn"> 
          <button
            type="submit"
            className="btn whatsapp_phonenumber_submit_btn modalBtn"
            disabled={!userInfo.email || formStatus.isLoading}
            onClick={handleSubmit}
          >
            {formStatus.isLoading ? (
              <i
                className="fa fa-spinner fa-spin mr-2 text-white"
                aria-hidden="true"
              />
            ) : formStatus.isSuccess ? (
              "Submitted"
            ) : (
              "Submit"
            )}
          </button>
          </div>
        </form></div>
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
