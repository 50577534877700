import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const TestimonialCard = ({ quote, text, logo, location, industry }) => {
  const { t } = useTranslation();
  return (
    <div className="card testimonial-card">
      <div className="card-body testimonial-card-body">
        <div>
          <h5 className="card-title testimonial-card-title">"{t(quote)}"</h5>
          <p className="card-text testimonial-card-text">{t(text)}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo} alt={logo} className="testimonial-logo" />
          <span
            className="card-text testimonial-card-text"
            style={{ color: "#5928D1",fontWeight: 600  }}
          >
            {t(location)}
          </span>
          <span className="card-text testimonial-card-text">{t(industry)}</span>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
