import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./HireAIExpertModal.css";
import WhatsappPhoneNumberForm from "../../whatsappBot/WhatsappPhoneNumberForm";
import EmailForm from "../../whatsappBot/EmailForm";
import { countrySelectorStyleProps, inputProps } from "../../../utils/style";
import { isValidEmail } from "../../../utils/validFormData";
import { isPhoneValid } from "../../../utils/phoneNumberValidation";
import { useTranslation } from "react-i18next";

const HireAIExpertModal = ({
  isModalVisible,
  resetForm,
  formStatus,
  errorMessage,
  setErrorMessage,
  handleEmailSubmission,
  handleWhatsappSubmission,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
}) => {
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState({
    name: "",
    companyName: "",
    details: "",
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmission = async () => {
    const nameRegex = /^[a-zA-Z\s]*$/;

    if (!nameRegex.test(userInfo.name)) {
      setErrorMessage(
        "Only alphabetic characters are allowed in the Name field."
      );
      return;
    }
    if (isValidEmail(email)) {
      setErrorMessage("");
      await handleEmailSubmission({
        first_name: userInfo.name,
        company_name: userInfo.companyName,
        email,
        message: userInfo.details,
      });
    } else if (isPhoneValid(phoneNumber)) {
      setErrorMessage("");
      await handleWhatsappSubmission({
        phone_number: phoneNumber,
        name: userInfo.name,
        company_name: userInfo.companyName,
        summarize_needs: userInfo.details,
      });
    } else {
      setErrorMessage("Please provide a valid email or phone number");
    }
  };

  return (
    <Modal show={isModalVisible} onHide={resetForm} centered>
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body style={{ padding: "0 24px 20px 24px" }}>
        <div className="title">
          <h2 className="gradient-text-default text-center">
            {t("hireaiexpertmodal.title")}
          </h2>
        </div>
        {formStatus.isSuccess && (
          <div className={`alert alert-success`} style={{ padding: 10 }}>
            {t("connectnowmodel.wewillconnectsoon")}
          </div>
        )}
        {formStatus.error && (
          <div className={`alert alert-danger`} style={{ padding: 10 }}>
            {formStatus.error}
          </div>
        )}
        <form
          className="d-flex flex-column"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="form-details">
            <div className="form-group">
              <label htmlFor="name" className="form-label">
                {t("hireaiexpertmodal.name")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control form-inputs"
                name="name"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="companyName">
                {t("hireaiexpertmodal.company")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control form-inputs"
                name="companyName"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="details">
              {t("hireaiexpertmodal.needs")}
              <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control form-inputs"
              name="details"
              rows="2"
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="form-divider">
            <span>{t("hireaiexpertmodal.connect")}</span>
          </div>
          <div className="contact-container">
            <WhatsappPhoneNumberForm
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              inputProps={inputProps}
              countrySelectorStyleProps={countrySelectorStyleProps}
            />
            <div className="or">{t("hireaiexpertmodal.or")}</div>
            <EmailForm email={email} setEmail={setEmail} />
          </div>
          <small
            className="form-text text-danger"
            style={{ textAlign: "center", marginBottom: "1rem" }}
          >
            {errorMessage}
          </small>
          <button
            type="submit"
            className="btn whatsapp_phonenumber_submit_btn modalBtn"
            disabled={
              (!email && phoneNumber.length <= 4) ||
              !userInfo.name.trim() ||
              !userInfo.companyName.trim() ||
              !userInfo.details.trim() ||
              formStatus.isLoading
            }
            onClick={handleSubmission}
          >
            {formStatus.isLoading ? (
              <i
                className="fa fa-spinner fa-spin mr-2 text-white"
                aria-hidden="true"
              />
            ) : (
              t("hireaiexpertmodal.submit")
            )}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default HireAIExpertModal;
