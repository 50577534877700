import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
class Footer extends React.Component {
  componentDidMount() {
    this.props.mounted();
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section className=" text-center p-2">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-social">
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="wow fadeInDown"
                        href="https://www.linkedin.com/company/fxisai/mycompany"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="social-icon" src="/images/SocialmediaIcons/LinkedIn 2.webp" alt="LinkedIn" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="wow fadeInUp"
                        href="https://www.instagram.com/fxis.ai/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="social-icon" src="/images/SocialmediaIcons/Instagram 2.webp" alt="Instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="wow fadeInDown"
                        href="https://www.facebook.com/fx.data.labs/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="social-icon" src="/images/SocialmediaIcons/Facebook 2.webp" alt="Facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="wow fadeInUp"
                        href="https://twitter.com/fxis_ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                       <img className="social-icon" src="/images/SocialmediaIcons/X 2.webp" alt="Twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="wow fadeInDown"
                        href={"mailto:contact@fxis.ai"}
                      >
                        <img  className="social-icon" src="/images/SocialmediaIcons/Email 2.webp" alt="Email" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <Link to="/privacy-policy" className="footer-nav">
                    {t("footer.privacypolicy")}
                  </Link>
                </div>
                <p className="company-about fadeIn">
                  &copy; {new Date().getFullYear()} {t("footer.rights")}
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const WrappedFooter = (props) => {
  const { t } = useTranslation();
  return <Footer {...props} t={t} />;
};

export default WrappedFooter;
