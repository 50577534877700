import React, { useEffect,useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Awards, AwardsMobile, AwardsTablet } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import "./awards.css";
import Skeleton from "react-loading-skeleton";
import scriptLoader from "../../../utils/javascriptsLoader";

const AwardSectionSlider = ({ slides }) => (
  <div id="awards_slider" className="owl-carousel">
    {slides.map((slide, index) => (
      <div key={index} className="slide-container">
        <div className="image-row row-1">
          {slide.row_1_images.map((image, idx) => (
            <div
              key={idx}
              className="awardImage"
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          ))}
        </div>
        <div className="image-row row-2">
          {slide.row_2_images.map((image, idx) => (
            <div
              key={idx}
              className="awardImage"
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);

const AwardSection = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 481px) and (max-width: 768px)",});
  const [loading, setLoading] = useState(true);
  const key = isMobile ? "mobile" : isTablet ? "tablet" : "desktop";

  useEffect(() => {
    props.mounted();
    loadCounterJs();
   // if (window.lazyLoadInstance) {
     // window.lazyLoadInstance.update();
   // }
    const owlCarousel = window.$("#awards_slider").owlCarousel();
    owlCarousel.trigger("destroy.owl.carousel");
  }, [key]);

  const loadCounterJs = () => {
    const awardsScript = {
      src:
          process.env.REACT_APP_BASE_URL +
          "js/modules/awards.js?ca93809a-cd54-40d0-ad2c-a763aba41856",
      async: 1,
      defer: 1,
      id: "technologiesId",
    };
      scriptLoader(awardsScript, onScriptLoad);
    };

  const onScriptLoad = () => {
    if (window.lazyLoadInstance) {
      window.lazyLoadInstance.update();
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <section className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center">
            <Skeleton height={40} width={`30%`} />
          </div>
        </div>
        <div className="container" style={{ margin: "20px auto" }}>
          <div className="skeleton-container">
            <Skeleton height={250} />
          </div>
        </div>
        <div className="col-md-12 text-center">
          <Skeleton height={15} width={`20%`} />
        </div>
      </section>
    );
  }

  return (
    <React.Fragment>
      <section className="container-fluid bg-light" id="awards">
        <div className="row">
          <div
            className="col-md-12 text-center wow fadeIn"
            style={{ marginBottom: "20px" }}
          >
            <div className="title d-inline-block">
              <h2 className={"gradient-text-default"}>{t("awards.title")}</h2>
            </div>
          </div>
        </div>
        <div className="awards-container" key={key}>
          {isMobile ? (
            <AwardSectionSlider slides={AwardsMobile} />
          ) : isTablet ? (
            <AwardSectionSlider slides={AwardsTablet} />
          ) : (
            <AwardSectionSlider slides={Awards} />
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default AwardSection;
