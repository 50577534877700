import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { IoMdArrowDropdown } from "react-icons/io";
import "./NavBarLinks.css";

function NavBarLinks() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="d-flex align-items-center navbar-new-link">
        {/* Experience Dropdown */}
        <div className="dropdown">
          <Link
            className="link scroll"
            to="#"
            id="experienceDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbarlinks.title1")}{" "}
            <IoMdArrowDropdown
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="experienceDropdown"
          >
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("clientele");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title1_link1")}
            </Link>

            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById(
                  "hire-ex-experts-screen"
                );
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title1_link2")}
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("testimonial");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title1_link3")}
            </Link>
          </div>
        </div>

        {/* Skills Dropdown */}
        <div className="dropdown">
          <Link
            className="link scroll"
            to="#"
            id="skillsDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbarlinks.title2")}{" "}
            <IoMdArrowDropdown
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="skillsDropdown"
          >
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("techstack");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title2_link1")}
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection =
                  document.getElementById("areaexpertise");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title2_link2")}
            </Link>
          </div>
        </div>

        {/* WorldRecords Dropdown */}
        <div className="dropdown">
          <Link
            className="link scroll"
            to="#"
            id="worldRecordsDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbarlinks.title3")}{" "}
            <IoMdArrowDropdown
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="worldRecordsDropdown"
          >
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("gwr");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title3_link1")}
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("awards");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title3_link2")}
            </Link>
          </div>
        </div>

        {/* Services Dropdown */}
        <div className="dropdown">
          <Link
            className="link scroll"
            to="#"
            id="servicesDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbarlinks.title4")}{" "}
            <IoMdArrowDropdown
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="servicesDropdown"
          >
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("services");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title4_link1")}
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("bussiness");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title4_link2")}
            </Link>
          </div>
        </div>

        {/* Products Dropdown */}
        <div className="dropdown">
          <Link
            className="link scroll"
            to="#"
            id="productsDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbarlinks.title5")}{" "}
            <IoMdArrowDropdown
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="productsDropdown"
          >
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("product-1");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title5_link1")}
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("product-2");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title5_link2")}
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("product-3");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title5_link3")}
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => {
                const clienteleSection = document.getElementById("product-4");
                clienteleSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("navbarlinks.title5_link4")}
            </Link>
          </div>
        </div>

        {/* Culture Dropdown */}
        <div className="dropdown">
          <Link
            className="link scroll"
            to="/culture"
            id="cultureDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbarlinks.title6")}{" "}
            <IoMdArrowDropdown
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="cultureDropdown"
          >
            <Link className="dropdown-item" to="/">
              {t("navbarlinks.title6_link1")}
            </Link>
            <Link className="dropdown-item" to="/">
              {t("navbarlinks.title6_link2")}
            </Link>
          </div>
        </div>

        {/* Blog Dropdown */}
        <div className="dropdown">
          <Link
            className="link scroll"
            to="/"
            id="blogDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Blog
          </Link>
        </div>

        {/* ContactUs Dropdown */}
        <div className="dropdown">
          <Link
            className="link scroll"
            to="/contactus"
            id="contactUsDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NavBarLinks;
