import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ClientRegistration = ({ isSideBar }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {isSideBar ? (
        <li className="nav-item">
          <button
            className="btn nav-btn-dev nav-link link"
            style={{ background: "transparent", border: "none" }}
            onClick={() => {
              ReactGA.event({
                category: "Hire Developers",
                action: "Click",
                label: "Hire Developers",
                value: 1,
              });
              document.getElementById("btn_sideNavClose").click();

              document.getElementById("hireai-expert-btn").click();
            }}
            id="btn_sideNavClient"
            s
          >
            {t("home.letscollaborate")}
          </button>
        </li>
      ) : (
        <button
          className="btn nav-btn-dev"
          id="btnDev"
          onClick={() => {
            ReactGA.event({
              category: "Hire Developers",
              action: "Click",
              label: "Hire Developers",
              value: 1,
            });
            document.getElementById("hireai-expert-btn").click();
          }}
        >
          {t("home.letscollaborate")}
        </button>
      )}
    </React.Fragment>
  );
};

export default ClientRegistration;
