import React, { useState, useEffect } from "react";
import "./TechStack.css";
import {
  HireExExpertsCompany,
  TechStackFullStack,
  TechStackFullStackTablet,
  TechStackDataScience,
  TechStackDataScienceTablet,
  TechStackDevOps,
  TechStackDevOpsTablet,
  TechStackFullStackMobile,
  TechStackDevOpsMobile,
  TechStackDataScienceMobile,
} from "../../../utils/constants";
import { useMediaQuery } from "react-responsive";

const TechStackSlider = ({ slides }) => (
  <div id="techstack_slider" className="owl-carousel">
    {slides.map((slide, index) => (
      <div key={index} className="slide-container">
        <div className="image-row-ts row-1">
          {slide.row_1_images.map((image, idx) => (
            <div
              key={idx}
              className="techstackImage"
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
        <div className="image-row-ts row-2">
          {slide.row_2_images.map((image, idx) => (
            <div
              key={idx}
              className="techstackImage"
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
        <div className="image-row-ts row-3">
          {slide.row_3_images?.map((image, idx) => (
            <div
              key={idx}
              className="techstackImage"
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);
const TechStackContainer = ({ activeCategory, slides }) => {
  return (
    <div className="techstack-container" key={activeCategory}>
      {activeCategory === "fullstack" && (
        <TechStackSlider slides={slides.fullstack} />
      )}
      {activeCategory === "devops" && (
        <TechStackSlider slides={slides.devops} />
      )}
      {activeCategory === "datascience" && (
        <TechStackSlider slides={slides.datascience} />
      )}
    </div>
  );
};
const TechStack = ({ loading, activeCategory, setActiveCategory }) => {
  // Set the key based on the current layout
  const isTablet = useMediaQuery({ minWidth: 702, maxWidth: 1439 });
  const isBigLaptop = useMediaQuery({ minWidth: 1440 });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const layout = isBigLaptop ? "desktop" : isTablet ? "tablet" : "mobile";

  const techStack = {
    desktop: {
      datascience: TechStackDataScience,
      fullstack: TechStackFullStack,
      devops: TechStackDevOps,
    },
    tablet: {
      datascience: TechStackDataScienceTablet,
      fullstack: TechStackFullStackTablet,
      devops: TechStackDevOpsTablet,
    },
    mobile: {
      datascience: TechStackDataScienceMobile,
      fullstack: TechStackFullStackMobile,
      devops: TechStackDevOpsMobile,
    },
  };

  return (
    <section className="container-fluid" id="techstack">
      <div className="row">
        <div className="col-md-12 text-center wow fadeIn">
          <div className="title d-inline-block">
            <h2 className={"gradient-text-default"}>TechStack</h2>
            <p className="techstack-desc">
              Never Settle for Less — Choose the Tech Stack That Delivers
            </p>
          </div>
        </div>
      </div>
      {isMobile ? (
        <div className="category-tabs">
          <div className="techstack-tab-row1">
            <div
              className={`techstack-tab ${
                activeCategory === "datascience" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("datascience")}
            >
              Data Science
            </div>
            <div
              className={`techstack-tab ${
                activeCategory === "fullstack" ? "active" : ""
              }`}
              onClick={() => {
                setActiveCategory("fullstack");
              }}
            >
              Full Stack
            </div>
          </div>
          <div className="techstack-tab-row2">
            <div
              className={`techstack-tab ${
                activeCategory === "devops" ? "active" : ""
              }`}
              onClick={() => {
                setActiveCategory("devops");
              }}
            >
              DevOps
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <div className="category-tabs">
            <div
              className={`techstack-tab ${
                activeCategory === "datascience" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("datascience")}
            >
              Data Science
            </div>
            <div
              className={`techstack-tab ${
                activeCategory === "fullstack" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("fullstack")}
            >
              Full Stack
            </div>
            <div
              className={`techstack-tab ${
                activeCategory === "devops" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("devops")}
            >
              DevOps
            </div>
          </div>
        </div>
      )}
      <div key={layout}>
        {layout === "desktop" ? (
          <TechStackContainer
            activeCategory={activeCategory}
            slides={techStack.desktop}
          />
        ) : layout === "tablet" ? (
          <TechStackContainer
            activeCategory={activeCategory}
            slides={techStack.tablet}
          />
        ) : layout === "mobile" ? (
          <TechStackContainer
            activeCategory={activeCategory}
            slides={techStack.mobile}
          />
        ) : null}
      </div>
    </section>
  );
};

export default TechStack;
