import React, { useState } from "react";
import "./AreaExpertics.css";
import { AreaExperticsData } from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const AreaExpertics = () => {
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState(
    AreaExperticsData[0]
  );

  const handleTitleClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <section id="areaexpertise" className="bg-light">
      <div className="row">
        <div className="container">
          <div className="row wow fadeIn">
            <div className="col-md-12 text-center">
              <div className="title d-inline-block">
                <h2 className="gradient-text-default">
                  {t("areaexpertise.title")}
                </h2>
              </div>
              <div className="row area-row">
                <div className="col-sm-12 text-center">
                  <div className="card-container">
                    <div className="title-tab-container">
                      {AreaExperticsData.map((item) => (
                        <div
                          key={item.id}
                          className={`title-tab ${
                            selectedCategory.id === item.id ? "active" : ""
                          }`}
                          // style={{ margin: item.id !== 4 ? "0 20px 0 0" : 0 }}
                          onClick={() => handleTitleClick(item)}
                        >
                          {t(item.title)}
                        </div>
                      ))}
                    </div>

                    {selectedCategory.cards.map((card, index) => (
                      <div key={index} className="card area-card">
                        <img src={card.image} alt={card.title} width={"50%"} />
                        <div className="card-title">{t(card.title)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AreaExpertics;
