import React from "react";
import Testimonial from "./TestimonySection/Testimonial";
import ServiceSection from "./ServiceSection/ServiceSection";
import Info from "./Info";
import Products from './ProductSection/Products'
import Footer from "./FooterSection/Footer";
import HireExExperts from "./HireExExpertSection/HireExExperts";
import Awards from "./AwardSection/Awards";
import Clientele from "./AboutSection/Clientele";
import GwrSection from "./GwrSection/GwrSection";

class OnDemandLoader extends React.Component {
  render() {
    const { addToList } = this.props;
    return (
      <React.Suspense fallback={""}>
        <Clientele mounted={addToList} />
        <HireExExperts mounted={addToList} />
        <Testimonial mounted={addToList} />
        <ServiceSection mounted={addToList} />
        <Awards mounted={addToList} />
        <GwrSection mounted={addToList} />
        <Products mounted={addToList} />
        <Info mounted={addToList} />
        <Footer mounted={addToList} />
      </React.Suspense>
    );
  }
}

export default OnDemandLoader;
