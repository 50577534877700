import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { servicesData } from "../../../utils/constants";
import './Services.css'
const ServiceCard = ({ service, openModal }) => {
  const { t } = useTranslation();

  return (
    <div className="service-card  text-center">
      <h4 className="service-card__title alt-font gradient-text1 d-inline-block">
        {t(service.cardTitle)}
      </h4>
      <div className="service-card__description">
        <p className="service-card__text no-margin">{t(service.cardDesc)}</p>
      </div>
      <div className="service-card__button-wrapper text-center" style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn-large service-card__button btn-rounded w-60 p-2"
          onClick={() => openModal(service.id)}
        >
          {t("services.hirenow")}
        </button>
      </div>
    </div>
  );
};

const ServiceModal = ({ service, closeModal }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={service.showModal}
      onHide={() => closeModal()}
      aria-labelledby={`${service.modalTitleId}`}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={`${service.modalTitleId}`}>
          {t(service.modalTitle)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {service.modalComponent}
      </Modal.Body>
    </Modal>
  );
};

const Services = () => {
  const [activeModal, setActiveModal] = useState(null);
  const { t } = useTranslation();

  const openModal = (id) => {
    setActiveModal(id);
    ReactGA.event({
      category: "Hire Now",
      action: "Click",
      label: "Hire Now",
      value: 1,
    });
  };

  const closeModal = () => setActiveModal(null);

  const activeService = servicesData.find(service => service.id === activeModal);

  return (
    <div className="service-container">
      <div className="row">
        <div className="col-md-12 text-center wow fadeIn">
          <div className="title d-inline-block">
            <h2 className="gradient-text-default">{t("services.title")}</h2>
          </div>
        </div>
      </div>
      <div className="row services__content-wrapper">
        <div className="services__cards-container">
          {servicesData.map((service) => (
            <ServiceCard key={service.id} service={service} openModal={openModal} />
          ))}
        </div>
      </div>
      {activeService && (
        <ServiceModal service={{ ...activeService, showModal: true }} closeModal={closeModal} />
      )}
    </div>
  );
};

export default Services;
