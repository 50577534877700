import React, { useState } from "react";
import SideBar from "./SideBar";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import scriptLoader from "../../utils/javascriptsLoader";
import { decrement, reset } from "../../redux/actions/sessionActions";

import ClientRegistration from "../Auth/ClientRegistration/ClientRegistration";
import DeveloperRegistration from "../Auth/DeveloperRegistration/DeveloperRegistration";
import Login from "../Auth/Login/Login";
import UserProfile from "../Auth/UserProfile/UserProfile";

import LanguageSelector from "../../utils/LanguageSelector";
import { useTranslation } from "react-i18next";
import { IoMdArrowDropdown } from "react-icons/io";
import NavBarLinks from "./NavBarLinks/NavBarLinks";

const NavBar = () => {
  const [navFlag, setNavFlag] = useState(false);
  const { isLoggedIn, user, tabs, count } = useSelector(
    (state) => state.sessionHandler
  );
  const { t } = useTranslation();
  const locator = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!document.getElementById("usageScriptId")) {
      const scriptParam = {
        id: "usageScriptId",
        src:
          process.env.PUBLIC_URL +
          "/js/modules/script.js?ca93809a-cd54-40d0-ad2c-a763aba41894",
        async: 1,
        defer: 1,
      };
      scriptLoader(scriptParam);
    }
    if (locator.pathname === "/") {
      dispatch(reset());
      setNavFlag(true);
      const handleScroll = () => {
        const heroSection = document.getElementById("home");
        const heroSectionHeight = heroSection.offsetHeight;
        const scrollPosition = window.scrollY;

        setNavFlag(locator.pathname === "/" && scrollPosition < 90);
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setNavFlag(false);
    }
  }, [locator.pathname]);

  const onLogoClick = () => {
    history.push("/");
    dispatch(reset());
  };

  const phoneNavUi = () => {
    if (count === 1) {
      return (
        <div style={{ display: "flex" }}>
          <button className="arrow-btn" onClick={() => dispatch(decrement())}>
            <img src="../images/arrow_left.svg" className="arrow" alt="arrow" />
          </button>
          <button className="step-btn1">Step 1/ 2</button>
        </div>
      );
    }

    if (count === 2) {
      return (
        <div style={{ display: "flex" }}>
          <button className="arrow-btn" onClick={() => dispatch(decrement())}>
            <img src="../images/arrow_left.svg" className="arrow" alt="arrow" />
          </button>
          <button className="step-btn2">Step 2/ 2</button>
        </div>
      );
    }
  };

  const navLogicUi = () => {
    if (locator.pathname === "/") {
      return (
        <>
          <NavBarLinks />
          <ClientRegistration />
        </>
      );
    }

    if (locator.pathname === "/privacy-policy") {
      return (
        <>
          <DeveloperRegistration />
          <ClientRegistration />
          <Login />
        </>
      );
    }

    if (locator.pathname === "/careers" || locator.pathname === "/apply_now") {
      return (
        <>
          <Link to="/hirenow" className="nav-link">
            {t("navbar.button2")}
          </Link>
          <Login />
        </>
      );
    }

    if (locator.pathname === "/hirenow" && count === 0) {
      return (
        <>
          <DeveloperRegistration />
          <Login />
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <header
        style={{
          position: "fixed",
        }}
        className={[
          "cursor-light h-90",
          locator.pathname !== "/" && "scoll-zero-bg",
        ]?.join(" ")}
      >
        <nav
          id="mainNavbar"
          className="navbar navbar-top-default navbar-expand-sm navbar-expand-lg nav-icon"
        >
          <div className="container1 w-100">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-center">
                <div
                  title="Logo"
                  onClick={onLogoClick}
                  className="logo link scroll d-inline-block"
                >
                  <img
                    src={
                      navFlag
                        ? process.env.PUBLIC_URL + "/images/logo-White.svg"
                        : process.env.PUBLIC_URL + "/images/logo-black.svg"
                    }
                    alt="logo"
                    className="logo-dark default logo-scroll-top-arrow"
                    width="64px"
                    height="35px"
                  />
                </div>
                <div className="language-selector-wrapper">
                  <LanguageSelector navFlag={navFlag} />
                </div>
              </div>

              <div className="d-flex align-items-center navUi">
                {!isLoggedIn ? (
                  <React.Fragment>{navLogicUi()}</React.Fragment>
                ) : (
                  <UserProfile />
                )}

                {isLoggedIn &&
                  tabs.map((tab) => (
                    <React.Fragment key={tab.id}>
                      {locator.pathname.indexOf(tab.path) !== -1 ? (
                        <a
                          className={[
                            "nav-link",
                            "link",
                            "scroll",
                            user.user_type === 3 && "developer-nav",
                          ]?.join(" ")}
                          href={`#sec_${tab.index}`}
                        >
                          {tab.label}
                        </a>
                      ) : (
                        <Link
                          className={[
                            "nav-link",
                            "link",
                            user.user_type === 3 && "developer-nav",
                          ]?.join(" ")}
                          to={tab.path}
                        >
                          {tab.label}
                        </Link>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>

            {count ? (
              phoneNavUi()
            ) : (
              <a
                className="d-inline-block parallax-btn sidemenu_btn show-hide-burger cursor-pointer"
                id="sidemenu_toggle"
                href="#menu"
              >
                <div className="animated-wrap sidemenu_btn_inner">
                  <div className="animated-element">
                    <span style={{ background: navFlag ? "#fff" : "#000" }} />
                    <span style={{ background: navFlag ? "#fff" : "#000" }} />
                    <span style={{ background: navFlag ? "#fff" : "#000" }} />
                  </div>
                </div>
              </a>
            )}
          </div>
        </nav>
        <SideBar />
      </header>
    </React.Fragment>
  );
};

export default NavBar;
