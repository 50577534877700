import React, { useEffect, useState } from "react";
import { clienteleData, clienteleTabletData, clienteleMobileData } from "../../../utils/constants";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import scriptLoader from "../../../utils/javascriptsLoader";
import "./Clientele.css";
import { useMediaQuery } from "react-responsive";

const ClienteleSlider = ({slides}) => (
  <div id="clientele_slider" className="owl-carousel">
    {slides.map((slide, index) => (
      <div key={index} className="slide-container">
        <div className="image-row-cli row-1">
          {slide.row_1_images.map((image, idx) => (
            <div key={idx} className="clienteleImageContainer">
              <img src={image} className="clienteleImage" alt="" />
            </div>
          ))}
        </div>
        <div className="image-row-cli row-2">
          {slide.row_2_images.map((image, idx) => (
            <div key={idx} className="clienteleImageContainer">
              <img src={image} className="clienteleImage" alt="" />
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

const Clientele = ({ mounted }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const isTablet = useMediaQuery({ maxWidth: 800 });
  const isMobile = useMediaQuery({ maxWidth: 480 }); // Add isMobile to detect mobile screens
  const layout = isMobile ? "mobile" : isTablet ? "tablet" : "desktop";

  useEffect(() => {
    mounted();
    loadCounterJs();
    const owlCarousel = window.$("#clientele_slider").owlCarousel();
    owlCarousel.trigger("destroy.owl.carousel");
  }, [layout]);

  const loadCounterJs = () => {
    const clienteleScript = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/clientele.js?ca93809a-cd54-40d0-ad2c-a763aba41895 ",
      async: 1,
      defer: 1,
      id: "clienteleJsId",
    };
    scriptLoader(clienteleScript, onScriptLoad);
  };

  const onScriptLoad = () => {
    if (window.lazyLoadInstance) {
      window.lazyLoadInstance.update();
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <>
        <section id="clientele">
          <div className="container-fluid text-center">
            <div className="col-md-12">
              <div className="gradient-text-default title">
                <Skeleton height={40} width={`30%`} />
              </div>
            </div>
            <div className="clientele-container" id="clientele-sec">
              <div className="clientele-left" style={{ display: "inline" }}>
                <Skeleton height={100} width={`80%`} />
                <Skeleton height={100} width={`80%`} />
              </div>
              <div className="clientele-right">
                <Skeleton height={200} width={`80%`} />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  return (
    <section id="clientele">
      <div className="container-fluid fadeIn text-center">
        <div className="col-md-12">
          <div className="title d-inline-block">
            <h2 className="gradient-text-default">{t("aboutus.title2")}</h2>
          </div>
        </div>
        <div className="container clientele-container">
          <div className="clientele-left" key={layout}>
          {isMobile ? (
              <ClienteleSlider slides={clienteleMobileData} /> // Use mobile data for mobile
            ) : isTablet ? (
              <ClienteleSlider slides={clienteleTabletData} /> // Use tablet data for tablets
            ) : (
              <ClienteleSlider slides={clienteleData} /> // Default to desktop data
            )}
          </div>
          <div className="clientele-right">
            <img
              src="../images/clientele/Group_1000001929.webp"
              alt=""
              style={{ width: "80%" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clientele;
