import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { achivementsData } from "../../../utils/constants";
import scriptLoader from "../../../utils/javascriptsLoader";
import { useMediaQuery } from "react-responsive";
import Skeleton from "react-loading-skeleton";
import "./GwrSection.css";

const GwrSection = ({ mounted }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 481px) and (max-width: 768px)",
  });
  useEffect(() => {
    mounted();
    loadGWRJs();
  }, []);

  const loadGWRJs = () => {
    const script = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/gwr.js?ca93809a-cd54-40d0-ad2c-a763aba41895",
      async: 1,
      defer: 1,
      id: "gwrJsId",
    };
    scriptLoader(script, onScriptLoad);
  };

  const onScriptLoad = () => {
    if (window.lazyLoadInstance) {
      window.lazyLoadInstance.update();
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <section id="gwr">
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-12 text-center wow fadeIn">
              <div className="title d-inline-block">
                <h2 className="gradient-text-default gwr-headding">
                  {t("gwrsection.title")}
                </h2>
              </div>
              <div>
                <p className="main-text-gwr">{t("gwrsection.desc1")}</p>
                <p className="main-text-gwr">{t("gwrsection.desc2")}</p>
                <p className="main-text-gwr">{t("gwrsection.desc3")}</p>
              </div>
            </div>
          </div>
          {isMobile && (
            <div
              className="skeleton-container"
              style={{ width: "100%", display: "flex" }}
            >
              <div
                className="left-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
            </div>
          )}

          {isTablet && (
            <div
              className="skeleton-container"
              style={{ width: "100%", display: "flex" }}
            >
              <div
                className="left-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
              <div
                className="center-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
            </div>
          )}

          {!isMobile && !isTablet && (
            <div
              className="skeleton-container"
              style={{ width: "100%", display: "flex" }}
            >
              <div
                className="left-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
              <div
                className="center-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
              <div
                className="right-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
            </div>
          )}
          <div className="mt-5">
            <p className="bottom-text-gwr">{t("gwrsection.footer")}</p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section id="gwr">
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-12 text-center wow fadeIn">
              <div className="title d-inline-block">
                <h2 className="gradient-text-default gwr-headding">
                  {t("gwrsection.title")}
                </h2>
              </div>
              <div>
                <p className="main-text-gwr">{t("gwrsection.desc1")}</p>
                <p className="main-text-gwr">{t("gwrsection.desc2")}</p>
                <p className="main-text-gwr">{t("gwrsection.desc3")}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              id="gwr_slider"
              className="owl-carousel mt-3 justify-content-center"
            >
              {achivementsData.map((item, index) => (
                <div key={index}>
                  <div className="card-gwr">
                    <img
                      className="img-gwr"
                      alt={item.img}
                      src="../images/logo_gwr.png"
                    />
                    <div className="card-text-gwr">
                      <p className="text-gwr">{t(item.text)}</p>
                    </div>

                    <img
                      className="logo-ind"
                      alt={item.img}
                      src={`../images/${item.img}`}
                    />
                  </div>
                  <div className="b-text">
                    <p>{t(item.btext)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-5">
            <p className="bottom-text-gwr">{t("gwrsection.footer")}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default GwrSection;
