import React, { useEffect, useState } from "react";
import Industries from "./Industries";
import scriptLoader from "../../../utils/javascriptsLoader";
import Services from "./Services";
import { useMediaQuery } from "react-responsive";
import AreaExpertics from "./AreaExpertics";
import TechStack from "../TechStackSection/TechStack";

const ServiceSection = (props) => {
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState("datascience");

  const isTablet = useMediaQuery({ minWidth: 702, maxWidth: 1023 });
  const isBigLaptop = useMediaQuery({ minWidth: 1440 });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  useEffect(() => {
    if (
      activeCategory !== "devops" ||
      (activeCategory === "devops" && !isBigLaptop)
    ) {
      props.mounted();
      if (window.lazyLoadInstance) {
        window.lazyLoadInstance.update();
      }
      setTimeout(() => {
        loadProgressBarJs();
      }, 5000);
      loadTechStackjs();
      loadIndustriesJs();
      const techStackOwlCarousel = window.$("#techstack_slider").owlCarousel();
      techStackOwlCarousel.trigger("destroy.owl.carousel");
      const industryOwlCarousel = window.$("#industries_slider").owlCarousel();
      industryOwlCarousel.trigger("destroy.owl.carousel");
    }
  }, [isTablet, isBigLaptop, activeCategory]);

  const loadProgressBarJs = () => {
    const script = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/progressbar.js?ca93809a-cd54-40d0-ad2c-a763aba41895",
      async: 1,
      defer: 1,
      id: "progressbarJsId",
    };
    scriptLoader(script);
  };

  const loadTechStackjs = () => {
    const TechStackScript = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/techstack.js?ca93809a-cd54-40d0-ad2c-a763aba41856",
      async: 1,
      defer: 1,
      id: "technologiesId",
    };
    scriptLoader(TechStackScript);
  };

  const loadIndustriesJs = () => {
    const script = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/industries.js?ca93809a-cd54-40d0-ad2c-a763aba41895",
      async: 1,
      defer: 1,
      id: "industriesJsId",
    };
    scriptLoader(script, setLoading(false));
  };

  return (
    <React.Fragment>
      <section id="services" className="p-0">
        <div className="bg-light">
          <div className="divider"></div>
          <Services />
          <div className="divider"></div>
        </div>
        <TechStack
          isTablet={isTablet}
          isBigLaptop={isBigLaptop}
          isMobile={isMobile}
          loading={loading}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
        <AreaExpertics />
        <div className="row">
          <div className="container-fluid" id="bussiness">
            <Industries
              isTablet={isTablet}
              isBigLaptop={isBigLaptop}
              loading={loading}
              isMobile={isMobile}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ServiceSection;
