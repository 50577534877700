import React, { useState } from "react";
import {useForm, Controller} from "react-hook-form";
import { useSelector } from "react-redux";
import axios from "axios";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useTranslation } from "react-i18next";

const OnDemandDev = () => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const { register, handleSubmit, control, watch, formState: { errors } } = useForm();
    const {errors: registerErrors, registerSuccessMessage, loaders} = useSelector(state => state.sessionHandler)
    const onSubmit = (data) => {
        const url = process.env.REACT_APP_APIURL + 'on-demand-developer';

        axios.post(url, data)
            .then(res => {
                if (res.data.success) {
                    setMessage(res.data);
                } else {
                    setError(res.data);
                }
            })
    };

    return (
        <>
            <section id="onDemandDeveloper" className="cursor-light py-0">

                <div className="col-sm-12">
                    {message !== null && <div className={`alert alert-success`} style={{padding: 10}}>{message.message}</div>}
                    {error !== null && <div className={`alert alert-danger`} style={{padding: 10}}>{error.errors}</div>}
                </div>

                <form className="default-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="full_name" className="required">
                                    {t("ondemanddev.fullname")}
                                </label>
                                <Controller
                                    as={<input className={'form-control'}/>}
                                    id={'full_name'}
                                    name={'full_name'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{required: true}}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.full_name && <span>{t("ondemanddev.alert1")}</span>}
                                </small>
                            </div>
                        </div>
                                    
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required" htmlFor="email">
                                {t("ondemanddev.emailid")}
                                </label>
                                <Controller
                                    as={<input className={'form-control'}/>}
                                    id={'email'}
                                    name={'email'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{
                                        required: true,
                                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    }}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.email &&
                                        <>
                                            {errors.email.type === 'required' && <span>{t("ondemanddev.alert2")}</span>}
                                            {errors.email.type === 'pattern' && <span>{t("ondemanddev.alert3")}</span>}
                                        </>
                                    }
                                </small>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required" htmlFor="phone_number">
                                {t("ondemanddev.phoneno")}
                                </label>
                                <Controller
                                    as={<PhoneInput defaultCountry={'in'} value={phone} onChange={(phone) => {
                                        setPhone(phone)
                                    }}/>}
                                    id={'phone_number'}
                                    name={'phone_number'}
                                    control={control}
                                    defaultValue={phone}
                                    rules={{
                                        required: true,
                                        pattern: /^((\d{1,3})\d{5}([- ]*)\d{5})$/
                                    }}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.phone_number &&
                                        <>
                                            {errors.phone_number.type === 'required' && <span>{t("ondemanddev.alert4")}</span>}
                                            {errors.phone_number.type === 'pattern' && <span>{t("ondemanddev.alert5")}</span>}
                                        </>
                                    }
                                </small>
                            </div>
                        </div>
                                    
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required" htmlFor="company">
                                {t("ondemanddev.companyname")}
                                </label>
                                <Controller
                                    as={<input className={'form-control'}/>}
                                    id={'company'}
                                    name={'company'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{required: true}}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.company && <span>{t("ondemanddev.alert6")}</span>}
                                </small>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="website_url">
                                    {t("ondemanddev.weburl")}
                                </label>
                                <Controller
                                    as={<input className={'form-control'}/>}
                                    id={'website_url'}
                                    name={'website_url'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{}}
                                />
                            </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required" htmlFor="no_of_developer">
                                {t("ondemanddev.noofdev")}
                                </label>
                                <Controller
                                    as={<select className="form-control">
                                            <option value="">Select Developers</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5+">5+</option>
                                        </select>
                                    }
                                    id={'no_of_developer'}
                                    name={'no_of_developer'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{required: true}}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.no_of_developer && <span>{t("ondemanddev.alert7")}</span>}
                                </small>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required" htmlFor="message">
                                {t("ondemanddev.message")}
                                </label>
                                <Controller
                                    as={<textarea className={'form-control min-h-50'}/>}
                                    id={'message'}
                                    name={'message'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{required: true, maxLength: 1000}}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.message &&
                                        <>
                                            {errors.message.type === 'required' && <span>{t("ondemanddev.alert8")}</span>}
                                            {errors.message.type === 'maxLength' && <span>{t("ondemanddev.alert9")}</span>}
                                        </>
                                    }
                                </small>
                            </div>
                        </div>

                        <div className="col-sm-12 text-center">
                            <button type="submit" className="btn btn-large btn-gradient btn-rounded mt-4" id="project_outsourcing_submit_btn">
                                {loaders.register && <i className="fa fa-spinner fa-spin mr-2" aria-hidden="true" disabled={loaders.register ? true : ''}/>}
                                <span>{loaders.register ? 'Submiting' : 'Submit'}</span>
                                {/* Submit */}
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
}

export default OnDemandDev;
