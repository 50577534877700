import React from "react";
import { useTranslation } from "react-i18next";
import CustomCard from "./CustomCard";
import {
  BusinessVerticalsCards,
  BusinessVerticalsTabletCards,
} from "../../../utils/constants";
import Skeleton from "react-loading-skeleton";

const BusinessVerticalsDevices = ({ slides }) => (
  <div id="industries_slider" className="owl-carousel">
    {slides.map((card, index) => (
      <div key={index} className="card-row">
        <CustomCard
          title={card.title}
          description={card.description}
          image={card.image}
        />
      </div>
    ))}
  </div>
);
const BusinessVerticalsTablet = ({ slides }) => (
  <div id="industries_slider" className="owl-carousel">
    {slides.map((slide, index) => (
      <div className="slide-container" style={{ gap: "20px" }} key={index}>
        <div className="card-row">
          {slide.row_1_images.map((card, idx) => (
            <CustomCard
              key={idx}
              title={card.title}
              description={card.description}
              image={card.image}
            />
          ))}
        </div>
        <div className="card-row">
          {slide.row_2_images.map((card, idx) => (
            <CustomCard
              key={idx}
              title={card.title}
              description={card.description}
              image={card.image}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);
const BusinessVerticalsBigLaptop = ({ slides }) => (
  <div className="card-row">
    {slides.map((card, index) => (
      <CustomCard
        key={index}
        title={card.title}
        description={card.description}
        image={card.image}
      />
    ))}
  </div>
);
const Industries = ({ isTablet, isBigLaptop, loading, isMobile }) => {
  const { t } = useTranslation();
  const key = isBigLaptop ? "bigLaptop" : isTablet ? "tablet" : "desktop";

  if (loading) {
    return (
      <section className="container">
        <div className="row" style={{ marginBottom: "1rem" }}>
          <div className="col-md-12 text-center">
            <Skeleton height={40} width={`30%`} />
          </div>
        </div>
        <div
          className="skeleton-container"
          style={{ width: "100%", display: "flex" }}
        >
          {isBigLaptop && (
            <>
              <div
                className="left-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
              <div
                className="center-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
              <div
                className="right-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
            </>
          )}
          {key === "tablet" && (
            <>
              <div
                className="left-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
              <div
                className="center-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
            </>
          )}
          {isMobile && (
            <>
              <div
                className="left-skeleton"
                style={{ flex: 1, padding: "0 1rem" }}
              >
                <Skeleton height={275} />
              </div>
            </>
          )}
        </div>
      </section>
    );
  }
  return (
    <React.Fragment>
      <div className="divider"></div>
      <div className="row" id="industries">
        <div className="col-md-12 text-center">
          <div className="title d-inline-block">
            <h2 className="gradient-text-default">{t("industries.title")}</h2>
          </div>
        </div>
      </div>
      <div className={`${isBigLaptop ? "container-fluid" : "container"}`}>
        <div className="row text-center justify-content-center" key={key}>
          {isBigLaptop && (
            <BusinessVerticalsBigLaptop slides={BusinessVerticalsCards} />
          )}
          {key === "tablet" && (
            <BusinessVerticalsTablet slides={BusinessVerticalsTabletCards} />
          )}
          {key === "desktop" && (
            <BusinessVerticalsDevices slides={BusinessVerticalsCards} />
          )}
        </div>
      </div>

      <div className="divider"></div>
    </React.Fragment>
  );
};
export default Industries;
