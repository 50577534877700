import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import ProtectedRoute from "../Components/Auth/ProtectedRoute";
import Developers from "../Components/Recruiter/Developers";
import ApplicationStatus from "../Components/Developer/ApplicationStatus";
import Calendly from "../Components/Admin/pages/calendly";
import UserHome from "../Pages/Home/UserHome";
import Hirenow from "../Pages/HireNow/Hirenow";
import Careers from "../Pages/Careers/Careers";
import ApplyNow from "../Pages/ApplyNow/ApplyNow";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import NotFound from "../Components/NotFound";

const Layout = () => {
    const { isLoggedIn, tabs } = useSelector(state => state.sessionHandler);
    return (
        <React.Fragment>
            <NavBar />
            {!isLoggedIn && (
                <Switch>
                    <Route exact path="/" component={UserHome} />
                    <Route path="/careers" component={Careers} />
                    <Route path="/apply_now" component={ApplyNow} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route component={NotFound} />  
                </Switch>
            )}

            {isLoggedIn && (
                <React.Fragment>
                    <ProtectedRoute
                        path="/recruiter/developers"
                        component={Developers}
                    />

                    <ProtectedRoute
                        path="/developer/application-status"
                        component={ApplicationStatus}
                    />

                    <ProtectedRoute
                        path="/admin/calendly"
                        component={Calendly}
                    />

                    <Redirect exact from={"/"} to={tabs[0].path} />
                </React.Fragment>
            )}
        <div id="aimated-cursor">
            <div id="cursor">
            <div id="cursor-loader" />
            </div>
        </div>
    </React.Fragment>
  );
};

export default Layout;
